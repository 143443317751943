<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.create_transfer_warehouse_reserve_warehousing") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row>
        <a-col :span="24">
          <a-steps :current="0" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.create_appointment')" />
            <a-step :title="$t('warehouse.add_shipment')" />
            <a-step class="mr-3" :title="$t('warehouse.edit_shipment')" />
          </a-steps>
        </a-col>
      </a-row>
      <a-form
        class="mt-3"
        ref="refForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{
          sm: { span: 10 },
          xl: { span: 12 },
        }"
        :rules="rules"
        :model="state"
      >
        <a-row>
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{
                sm: { span: 10 },
                xl: { span: 12 },
              }"
              :label="$t('warehouse.transfer_warehouse')"
              name="warehouseId"
            >
              <a-select
                v-model:value="state.warehouseId"
                allow-clear
                :showSearch="true"
                :loading="dropDownData.warehouseListLoading"
                @change="handleChangeWarehouse"
                optionFilterProp="search-key"
              >
                <a-select-option
                  v-for="(value, key) in dropDownData.warehouseList"
                  :key="key"
                  :value="value.id"
                  :warehouseAddress="value"
                  :title="`${value.warehouseNo}(${value.warehouseName})`"
                  :search-key="value.warehouseNo + value.warehouseName"
                >
                  {{ value.warehouseNo }}({{ value.warehouseName }})
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 16 }"
          >
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{
                sm: { span: 18 },
                xl: { span: 18 },
              }"
              v-if="state.warehouseId && state.warehouseAddress"
              :label="$t('warehouse.warehouse_address')"
            >
              {{ getAddress() }}
              <span class="ml-2" v-if="state.warehouseAddress">
                {{ $t("logistics.contacts") }}:
                {{ getLinkman(state.warehouseAddress) }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.type_of_shipping')"
              name="transportationMethod"
            >
              <a-select
                v-model:value="state.transportationMethod"
                allow-clear
                @change="handleTypeShipping"
              >
                <a-select-option
                  v-for="(value, key) in transportationMethodEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('transportationMethod', value))"
                >
                  {{ $t($enumLangkey("transportationMethod", value)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            state.transportationMethod ==
            transportationMethodEnum.commercialExpress
          "
        >
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.delivery_way')"
              name="deliveryType"
            >
              <a-select v-model:value="state.deliveryType" allow-clear>
                <a-select-option
                  v-for="(value, key) in deliveryWayEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('deliveryWay', value))"
                >
                  {{ $t($enumLangkey("deliveryWay", value)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            state.transportationMethod ==
            transportationMethodEnum.commercialExpress
          "
        >
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.tracking_number')"
              name="trackingNo"
            >
              <a-input v-model:value="state.trackingNo"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            state.transportationMethod == transportationMethodEnum.container
          "
        >
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.container_type')"
              name="containerType"
            >
              <a-select v-model:value="state.containerType" allow-clear>
                <a-select-option
                  v-for="(value, key) in containerTypeEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('containerType', value))"
                >
                  {{ $t($enumLangkey("containerType", value)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          v-if="
            state.transportationMethod == transportationMethodEnum.container
          "
        >
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.container_no')"
              name="containerNo"
            >
              <a-input v-model:value="state.containerNo"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 8 }"
          >
            <a-form-item
              :label="$t('warehouse.estimated_arrival_date')"
              name="wareZoneAppointmentDateMoment"
            >
              <a-date-picker
                :disabled="!state.warehouseId"
                @change="handleSelectedDate"
                :disabled-date="disabledDate"
                :disabled-time="disabledDateTime"
                v-model:value="state.wareZoneAppointmentDateMoment"
                :show-time="{
                  format: 'HH:00',
                  defaultValue: moment(
                    new Date().getHours() + 1 + ':00:00',
                    'HH:mm'
                  ),
                }"
              />
              <a-tag color="warning" class="mt-1 ml-1">{{
                $t("warehouse.local_time")
              }}</a-tag>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <span class="text-warning">
              <InfoCircleOutlined />
              {{ $t("warehouse.reserve_warehousing_warning") }}
            </span>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center" :gutter="[64, 12]" class="mt-4">
          <a-col>
            <a-popconfirm
              :title="$t('common.are_you_sure')"
              @confirm="handleCancel"
            >
              <a-button>
                {{ $t("common.cancel") }}
              </a-button>
            </a-popconfirm>
          </a-col>

          <a-col>
            <a-button type="primary" @click="handleShowPlanModal">{{
              $t("common.next_step")
            }}</a-button>
          </a-col>
        </a-row>
      </a-form>

      <!-- 添加计划的名称 -->
      <a-modal
        v-model:visible="planNameModal.visible"
        :title="$t('warehouse.add_plan_name')"
        :footer="null"
        :centered="true"
      >
        <a-spin :spinning="planNameModal.loading">
          <div>
            <input type="text" class="display-none" />
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <p class="height-100">
                  {{ $t("warehouse.please_input_plan_name") }}
                </p>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center" align="middle">
              <a-col :span="15">
                <a-input
                  :maxlength="64"
                  v-model:value="planNameModal.planName"
                  required="true"
                  ref="refInputAddPlanName"
                  @focus="handleFocusSelectValue($event)"
                  @pressEnter="handleCreatePlan"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="mt-4 mb-1" type="flex" justify="center" align="middle">
            <a-col class="mr-4">
              <a-button @click="planNameModal.visible = false">{{
                $t("common.cancel")
              }}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleCreatePlan">{{
                $t("warehouse.created_plan")
              }}</a-button>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { defineComponent, reactive, onMounted, onActivated, ref } from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  Steps,
  message,
  Modal,
  Popconfirm,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import { getWarehouseList } from "../../../api/modules/transit/inPlan/list";
import { create } from "../../../api/modules/transit/inPlan/plan";
import {} from "../../../api/modules/common/index";
import {
  transportationMethod as transportationMethodEnum,
  deliveryWay as deliveryWayEnum,
  containerType as containerTypeEnum,
} from "../../../enum/enum.json";
import {
  getLinkman,
  getAddressByLanguageV2,
  currentTimeToUtc,
  createAutoPlanName,
} from "../../../utils/general";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "../../../hooks/tabs/index";
import moment from "moment-timezone";
import { isShipmentNoValid } from "@/utils/regexpValidator";

export default defineComponent({
  name: "transit_in_plan_create",
  components: {
    ATooltip: Tooltip,
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ADatePicker: DatePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    AModal: Modal,
    APopconfirm: Popconfirm,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const refForm = ref();
    const { delVisitedRoute } = useTab();

    const state = reactive({
      warehouseId: null,
      transportationMethod: null,
      deliveryType: null,
      trackingNo: null,
      containerType: null,
      containerNo: null,
      loading: false,
      warehouseAddress: null,
      wareZoneAppointmentDate: null,
      wareZoneAppointmentDateMoment: null,
      wareZoneAppointmentLimitHours: 48,
    });

    //计算仓库时区时间
    const getWareZoneNowMoment = () => {
      var now = moment();
      if (state.warehouseAddress?.timezone) {
        now = moment().tz(state.warehouseAddress.timezone).local(true);
      }
      return now;
    };

    const dropDownData = reactive({
      warehouseList: [],
      warehouseListLoading: false,
    });

    //start:禁用的开始小时
    //end:禁用的结束小时
    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    const disabledDate = (current) => {
      return (
        moment(current)
          .endOf("day")
          .startOf("hours")
          .diff(getWareZoneNowMoment(), "hours") <
        state.wareZoneAppointmentLimitHours
      );
    };

    const disabledDateTime = () => {
      return {
        disabledHours: () => {
          var afterXxHours = getWareZoneNowMoment().add(
            state.wareZoneAppointmentLimitHours,
            "hours"
          );
          var hour = afterXxHours.hour();
          if (
            moment(state.wareZoneAppointmentDateMoment).isSame(
              afterXxHours,
              "day"
            )
          ) {
            return range(0, hour + 1);
          } else {
            return range(0, 0);
          }
        },
      };
    };

    const funcGetWarehouseList = () => {
      dropDownData.warehouseListLoading = true;
      getWarehouseList({ isActive: true })
        .then(({ result }) => {
          if (result) {
            dropDownData.warehouseList = result;
          } else {
            dropDownData.warehouseList = [];
          }
          dropDownData.warehouseListLoading = false;
        })
        .catch(() => {
          dropDownData.warehouseListLoading = false;
        });
    };

    const validatePackageNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.tracking_number"),
          ])
        );
      } else {
        let sourceType = state.deliveryType;
        if (!isShipmentNoValid(sourceType, value)) {
          return Promise.reject(
            vueI18n.t("warehouse.please_enter_the_correct_p0", [
              vueI18n.t("warehouse.tracking_number"),
            ])
          );
        } else {
          return Promise.resolve();
        }
      }
    };

    const rules = {
      warehouseId: [
        {
          required: true,
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.transfer_warehouse"),
            ]);
          },
        },
      ],
      transportationMethod: [
        {
          type: "number",
          required: true,
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.type_of_shipping"),
            ]);
          },
        },
      ],
      deliveryType: [
        {
          required: true,
          type: "number",
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.delivery_way"),
            ]);
          },
        },
      ],
      wareZoneAppointmentDateMoment: [
        {
          required: true,
          validator: async (rule, value) => {
            if (!value) {
              return Promise.reject(
                vueI18n.t("common.p0_is_required", [
                  vueI18n.t("warehouse.estimated_arrival_date"),
                ])
              );
            }
            if (
              moment(value).diff(getWareZoneNowMoment(), "hour") <
              state.wareZoneAppointmentLimitHours
            ) {
              return Promise.reject(
                vueI18n.t(
                  "warehouse.reservations_are_required_p0_hours_in_advance",
                  [state.wareZoneAppointmentLimitHours]
                )
              );
            }
            return Promise.resolve();
          },
        },
      ],
      trackingNo: [
        {
          required: true,
          whitespace: true,
          validator: validatePackageNo,
          trigger: ["blur", "change"],
          // message: () => {
          //   return vueI18n.t("common.p0_is_required", [
          //     vueI18n.t("warehouse.tracking_number"),
          //   ]);
          // },
        },
      ],
      containerType: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.container_type"),
            ]);
          },
        },
      ],
      containerNo: [
        {
          required: true,
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.container_no"),
            ]);
          },
        },
      ],
    };

    const handleChangeWarehouse = (option, expand) => {
      state.warehouseAddress = expand?.warehouseAddress;
    };

    const handleTypeShipping = () => {
      //这个暂时好像无用
    };

    const getAddress = () => {
      if (!state.warehouseAddress) {
        return;
      }
      return getAddressByLanguageV2(state.warehouseAddress, getters.language);
    };

    const handleSelectedDate = (e) => {
      //要转换为仓库的UTC时间
      state.wareZoneAppointmentDateMoment = e;
    };

    //-----------------------添加计划名称 start------------------------------------
    const planNameModal = reactive({
      loading: false,
      visible: false,
      planName: null,
    });

    const handleCreatePlan = () => {
      if (
        !planNameModal.planName ||
        planNameModal.planName?.trim()?.length == 0
      ) {
        return message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.plan_name")])
        );
      }
      planNameModal.loading = true;

      state.wareZoneAppointmentDate = currentTimeToUtc(
        state.wareZoneAppointmentDateMoment?.format("YYYY-MM-DD HH:mm:ss"),
        state.warehouseAddress.timezone
      );

      create({ ...state, planName: planNameModal.planName })
        .then(({ result }) => {
          planNameModal.loading = false;
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "transit_in_plan_add_shipment",
            params: { id: result },
          });
        })
        .catch(() => {
          planNameModal.loading = false;
        });
    };

    const refInputAddPlanName = ref();

    const handleShowPlanModal = () => {
      refForm.value
        .validate()
        .then(({ result }) => {
          planNameModal.planName = createAutoPlanName();
          planNameModal.visible = true;
          setTimeout(() => {
            refInputAddPlanName.value?.focus();
          }, 100);
        })
        .catch(() => {});
    };

    const handleFocusSelectValue = (event) => {
      event.currentTarget.select();
    };
    //-----------------------添加计划名称 end------------------------------------

    const handleCancel = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({
        name: "transit_in_plan_list",
      });
    };

    onMounted(() => {
      funcGetWarehouseList();
    });

    onActivated(() => {});

    return {
      state,
      dropDownData,
      refForm,
      rules,
      transportationMethodEnum,
      deliveryWayEnum,
      containerTypeEnum,
      disabledDate,
      disabledDateTime,
      moment,

      getAddress,
      getLinkman,
      handleChangeWarehouse,
      handleTypeShipping,
      handleSelectedDate,

      planNameModal,
      handleCreatePlan,
      handleShowPlanModal,
      handleFocusSelectValue,
      refInputAddPlanName,
      handleCancel,
    };
  },
});
</script>

<style lang="less" scoped></style>
